import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Button, Menu, Form, Modal, message } from 'antd';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import WorkflowAction from '../../components/Workflow'

const useStyles = createUseStyles({
    modal: {
        '& .ant-modal-title': {
            marginRight: "25px"
        }
    }
})

export default function Action(props) {
    const classes = useStyles()
    const meta = useSelector(state => state.meta);
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    // const [fetched, setFetched] = React.useState(false);
    const [form] = Form.useForm();
    
    // var form = undefined;
    // if (props.form) {
    //     [form] = Form.useForm();
    // }
    // console.log(form);

    const scheme = meta[props.document];
    const ContentForm = props.form;
    const { callback, mode, hideMenu } = props;

    // const onSubmit = useCallback((values) => {
    //     action(values)
    // }, []);

    const closePopup = useCallback(() => {
        setOpened(false);
    }, []);

    // var available = true;
    // useEffect(
    //     () => {
    //         available = true;
    //         return () => {
    //             available = false;
    //         };
    //     },
    //     [ /* TODO: memoization parameters here */]
    // );
    const unlock = () => {
        setLoading(false);
    };
    const close = () => {
        setOpened(false);
    };
    const done = () => {
        close();
        unlock();
    };
    const action = (values) => {
        if (props.action) {
            setLoading(true);
            props.action(done, values, unlock, close);
        }
        else {
            setLoading(true);
            WorkflowAction(props.auth.fetch, `/api/` + props.document.toLowerCase(), scheme,
                (doc) => {
                    var v = (props.mix) ? props.mix(values) : values;
                    return { ...doc, ...v }
                },
                (res) => {
                    // if (available===true) {
                        if (res.status && res.status === true) {
                            done();
                            if (callback) {
                                callback(res);
                            }
                        } else {
                            message.error(res.message);
                        }
                        setLoading(false);
                    // }
                });
        }
    };
    const click = () => {
        // if (available===true) {
            setOpened(true);
        // };

        if (hideMenu) {
            hideMenu();
        }
    }
    // const items = (scheme && scheme.properties) ? scheme.properties.map(p => (
    //     <Form.Item 
    //         key={p.uuid}
    //         name={uncapitalize(p.name)}
    //         label={p.label}
    //         rules={[{ required: true, message: 'Введите описание желания стать членом федерации!' }]}
    //     >
    //         {p.type === "string" && <TextArea rows={4} />}
    //         {p.type === "string" && <Input />}
    //         {p.type === "uint" && <InputNumber style={{ width: "50%" }} />}
    //         {p.type === "date" && <DatePicker locale={locale} />}
    //         {p.type === "object" && <Select>
    //             <Option value="jack">Jack</Option>
    //             <Option value="lucy">Lucy</Option>
    //             <Option value="Yiminghe">yiminghe</Option>
    //         </Select>}
    //     </Form.Item>
    // )) : <></>;

    const width = (props.modal) ? props.modal.width : undefined;
    const title = (props.modal && props.modal.title) ? props.modal.title : props.title;
    const { readonly } = props;
    const footer = () => {
        if (readonly) {
            return [
                <Button key="back" onClick={closePopup}>
                    Закрыть
            </Button>
            ]
        } else {
            const onClick = (form)? form.submit : undefined;
            return [
                <Button key="back" onClick={closePopup}>
                    Отмена
            </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onClick}>
                    Отправить
            </Button>,
            ]
        }
    };
    return (
        <>
            <Modal
                width={width}
                className={classes.modal}
                title={title}
                visible={opened}
                closable={true}
                destroyOnClose={true}
                // onOk={form.submit}
                onCancel={closePopup}
                // okText={"Отправить"}
                // cancelText={"Отмена"}
                // confirmLoading={loading}
                footer={footer()}
            >
                <Spin spinning={loading}>
                    {props.form && <ContentForm {...props} submit={action} form={form} />}

                    {/* {!props.form && <Form
                        form={form}
                        onFinish={action}
                        labelAlign={"left"}
                        layout={"vertical"}
                    >
                        {items}
                    </Form>} */}
                </Spin>
            </Modal>
            {mode !== "MenuItem" ? <div className="buttons" loading={loading.toString()}><Button onClick={click} style={{ minWidth: "100%", textAlign: "left" }}>{props.title}</Button></div>
                : <Menu.Item
                    // {...props} - так делать нельзя, потому что тогда все свойства переданные в Action передаются в Menu.Item и мешают его нормальной работе
                    active={props.active}
                    builtinPlacements={props.builtinPlacements}
                    direction={props.direction}
                    eventKey={props.eventKey}
                    expandIcon={props.expandIcon}
                    focusable={props.focusable}
                    forceSubMenuRender={props.forceSubMenuRender}
                    index={props.index}
                    inlineIndent={props.inlineIndent}
                    itemIcon={props.itemIcon}
                    manualRef={props.manualRef}
                    motion={props.motion}
                    multiple={props.multiple}
                    onDeselect={props.onDeselect}
                    onOpenChange={props.onOpenChange}
                    onSelect={props.onSelect}
                    openKeys={props.openKeys}
                    parentMenu={props.parentMenu}
                    prefixCls={props.prefixCls}
                    renderMenuItem={props.renderMenuItem}
                    selectable={props.selectable}
                    selectedKeys={props.selectedKeys}
                    subMenuCloseDelay={props.subMenuCloseDelay}
                    subMenuKey={props.subMenuKey}
                    subMenuOpenDelay={props.subMenuOpenDelay}
                    triggerSubMenuAction={props.triggerSubMenuAction}

                    rootPrefixCls={props.rootPrefixCls}
                    disabled={props.disabled}
                    level={props.level}
                    title={props.title}
                    onItemHover={props.onItemHover}
                    onClick={click}
                >{props.title}</Menu.Item>
            }
        </>
    );
}