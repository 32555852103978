import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Menu, Card, Dropdown, Row, Col, Tabs, Tag, Modal, Select, message, Empty, Tooltip, Pagination } from 'antd';

import {
	ExclamationCircleOutlined,
	CloseCircleOutlined,
	OrderedListOutlined,
	CloseOutlined,
	PrinterOutlined,
	StarOutlined,
	CheckOutlined,
	CheckCircleOutlined,
	IssuesCloseOutlined,
	DownOutlined
} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'
import Filtered from '../../components/Filtered'

import REDUX_Tour from '../../objects/Tour/reducer';
import CRUDE_Tour from '../../objects/Tour/crude';
import REDUX_Event from '../../objects/Event/reducer';
import CRUDE_Event from '../../objects/Event/crude';
import REDUX_LevelEvent from '../../objects/LevelEvent/reducer';
import CRUDE_LevelEvent from '../../objects/LevelEvent/crude';
import REDUX_DancerEvent from '../../objects/DancerEvent/reducer'
import CRUDE_DancerEvent from '../../objects/DancerEvent/crude'
import REDUX_Protocol from '../../objects/Protocol/reducer';
import CRUDE_Protocol from '../../objects/Protocol/crude';
import Protocol from '../../forms/Protocol'

import { GET } from '../../components/Tool'

const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;
const { CheckableTag } = Tag;

const statPrepair = (protocol, s) => {
	var s = s || {};
	protocol.protocolParams.forEach(item => {
		if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
		s[item.criterionParams.criterionID].value += (!!item.value) ? item.value : 0;
		s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

		if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
			s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {}, good: true };
		}
		var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
		if (!countValue) {
			s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
		} else {
			s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
		}

		if (protocol.tour.levelEvent.maxCountEqualScores && item.value && s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.tour.levelEvent.maxCountEqualScores) {
			s[item.criterionParams.criterionID][item.criterionParamsID].good = false;
			s[item.criterionParams.criterionID].good = false;
		}
	});
	return s;
};
const protocolCriterionParamsCount = (protocol, protocolCriterionParams) => {
    var count = 0;
    protocolCriterionParams.forEach(protocolCriterian => {
        if (protocol.dancer.dancer.genderID === 1) {
            if (protocolCriterian.ParamsMan && protocolCriterian.ParamsMan.length) {
                    count++;
            }
        } else {
            if (protocolCriterian.ParamsWoman && protocolCriterian.ParamsWoman.length) {
                    count++;
            }
        }
    });
    return count;
};
const stat = (protocol, criterionCount) => {
	criterionCount = criterionCount || 0;
	var count = 0;
	if (!protocol) return;

	var s = statPrepair(protocol);

	if (protocol.EditedProtocols) {
		protocol.EditedProtocols.forEach(p => {
			s = statPrepair(p, s);
		});
	}

	var good = true;
	var check = true;
	if (Object.keys(s).length !== criterionCount) {
		good = false;
	} else {
		Object.keys(s).forEach(i => {
			if (!s[i].good) {
				good = false;
			}
		});
	}

	Object.keys(s).forEach(i => {
		if (!s[i].check) {
			check = false;
		}
	});

	return {
		...s,
		good: good,
		check: check
	}
};

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object, onSetCurrentTour } = props;
	const empty = (done) => {
		if (done) done();
	};
	const Tours = useSelector(REDUX_Tour.selector.arrayByKey(object.levelEvent.ID));
	const odelete = () => {
		CRUDE_Tour.destroy(props.auth, () => {
			dispatch(REDUX_Tour.actions.setArrayByKey(object.levelEvent.ID, Tours.filter(item => item.ID !== object.ID)));
		}, props.object.ID);
	};
	const makecurrent = () => {
		GET(auth, "/api/setcurrenttour/" + object.ID,
			() => {
				if (object.levelEvent) {
					CRUDE_Tour.read(auth, (data) => {
						if (data.length > 0) {
							dispatch(REDUX_Tour.actions.setArrayByKey((object.levelEvent) ? object.levelEvent.ID : 0, data));
							if(onSetCurrentTour){
								onSetCurrentTour(object.number)
							}
						}
					}, 'model', "s-number=ASC&w-levelEvent.id=" + ((object.levelEvent) ? object.levelEvent.ID : 0));
				}
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						message.error(err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						message.error(err);
					}
				}
			});
	};
	return (
		<DropdownAction>
			<Menu.Item style={{ color: "blue" }} onClick={() => confirm({
				title: (props.object.current) ? 'Вы действительно хотите убрать у тура статус текущий?' : 'Вы действительно хотите назначить тур текущим?',
				icon: <ExclamationCircleOutlined />,
				content: 'При назначении тура текущим у всех судей в мобильном интерфейсе будут отображаться протоколы этого тура для заполнения',
				okText: (props.object.current) ? 'Отменить статус текущего' : 'Сделать текущим',
				cancelText: 'Отмена',
				onOk() {
					makecurrent();
				},
				onCancel() {
				},
			})}>{(props.object.current) ? 'Отменить статус текущего' : 'Сделать текущим'}</Menu.Item>
			{/* <Action {...props} readonly={true} modal={{ title: "Танцоры тура", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Танцоры тура"} form={DancerTour} document={"dancerTour"} /> */}
			{/* <Action {...props} readonly={true} modal={{ title: "Судьи тура", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Судьи тура"} form={JudgeTour} document={"judgeTour"} /> */}
			{/* <Menu.Divider></Menu.Divider>
			<Menu.Item style={{ color: "red" }} onClick={() => confirm({
				title: 'Вы действительно хотите удалить тур?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление тура приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item> */}
		</DropdownAction>
	)
};

function BtnProtocol(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const prt = useSelector(REDUX_Protocol.selector.arrayByKey(object.tour.levelEventID));
	const odelete = () => {
		auth.fetch("/api/clearprotocolparams/" + props.object.ID).then(res => {
			if (res && res.status) {
				dispatch(REDUX_Protocol.actions.updateArrayByKey(object.tour.levelEventID, [res.data]));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	return (
		<DropdownAction>
			{/* <Action {...props} readonly={true} modal={{ title: "Танцоры тура", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Танцоры тура"} form={DancerTour} document={"dancerTour"} /> */}
			<Action {...props} readonly={true} modal={{ title: "Судейский протокол № " + object.tour.number + "-" + object.number, width: "1100px" }} object={props.object} mode={"MenuItem"} action={empty} title={"Протокол"} form={Protocol} document={"protocol"} />
			<Menu.Item key="print" icon={<PrinterOutlined />}>
				<a target="_blank" rel="noopener noreferrer" href={"/printprotocol/" + ((object) ? object.ID : 0)}>Печать</a>
			</Menu.Item>
			<Menu.Item key="print1" icon={<PrinterOutlined />}>
				<a target="_blank" rel="noopener noreferrer" href={"/printpivotprotocol/" + ((object) ? object.judgeID : 0)}>Сводный протокол</a>
			</Menu.Item>
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{ color: "red" }} onClick={() => confirm({
				title: 'Вы действительно хотите удалить оценки из протокола?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление оценок протокола приведет к потере всех внесенных ранее данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить все оценки</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	},
	link: {
		'&:hover': {
			color: "#40a9ff !important"
		}
	}
})

export default function CommonTour(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const [loadingTour, setLoadingTour] = useState(false);
	const [loadingProtocol, setLoadingProtocol] = useState(false);
	const [deleteProtocols, setDeleteProtocols] = useState(false);
	const [dancerResultW, setDancerResultW] = useState([]);
	const [dancerResultM, setDancerResultM] = useState([]);
	const [currentTour, setCurrentTour] = useState(1);

	const columns = [
		{
			title: 'Номер',
			render: (text, record) => {
				return (record.number);
			}
		},
		{
			title: 'Текущий тур',
			render: (text, record) => {
				return ((record.current === true) ? <CheckOutlined /> : <></>);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} onSetCurrentTour={setCurrentTour}/>),
		},
	];

	useEffect(() => {
		CRUDE_LevelEvent.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_LevelEvent.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const evdata = useSelector(REDUX_LevelEvent.selector.array);
	const context = () => {
		const e = localStorage.getItem('levelevent');
		const x = localStorage.getItem('event');
		var o = evdata.find(item => item.ID.toString() === e);
		if (o && x && o.event.ID.toString() !== x) {
			return undefined;
		}
		return o;
	};
	const [levelEvent, setLevelEvent] = useState((evdata.length <= 0) ? undefined : context());

	useEffect(() => {
		const e = localStorage.getItem('levelevent');
		if (e && evdata && evdata.length && !levelEvent) {
			var o = context();
			if (o) {
				setLevelEvent((evdata.length <= 0) ? undefined : o);
				CRUDE_Protocol.read(auth, (data) => {
					if (data.length > 0) {
						dispatch(REDUX_Protocol.actions.setArrayByKey(o.ID, data));
					}
				}, 'model', 's-tour.number=ASC&s-number=ASC&w-tour.levelEvent.id=' + o.ID);
			}
		}
	}, [evdata, levelEvent]);

	const levelEventChange = (value) => {
		var ev = evdata.find(item => item.ID === value);
		setLevelEvent(ev);
		if (ev) {
			localStorage.setItem('event', ev.event.ID);
			localStorage.setItem('levelevent', ev.ID);
			CRUDE_Protocol.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_Protocol.actions.setArrayByKey(ev.ID, data));
				}
			}, 'model', 's-tour.number=ASC&s-number=ASC&w-tour.levelEvent.id=' + ev.ID);
			setDancerResultW([]);
			setDancerResultM([]);
		}
	};
	const levelEvents = evdata.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.event.name + " (" + item.level.name + ")"}</Option>
		);
	});

	const [protocolCriterionParams, setProtocolCriterionParams] = useState([]);
	useEffect(() => {
		if (levelEvent) {
			setLoading(true);
			CRUDE_Tour.read(auth, (data) => {
				setLoading(false);
				if (data.length > 0) {
					dispatch(REDUX_Tour.actions.setArrayByKey((levelEvent) ? levelEvent.ID : 0, data));
				}
			}, 'model', "s-number=ASC&w-levelEvent.id=" + ((levelEvent) ? levelEvent.ID : 0));

			auth.fetch("/api/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/protocolcriterionparams").then(res => {
				if (res && res.data) {
					setProtocolCriterionParams(res.data);
				}
			});
		}
	}, [levelEvent, auth, dispatch]);

	const protocols = useSelector(REDUX_Protocol.selector.arrayByKey((levelEvent) ? levelEvent.ID : 0));

	const data = useSelector(REDUX_Tour.selector.arrayByKey((levelEvent) ? levelEvent.ID : 0));

	useEffect(() => {
		var tour = data.find(i=>i.current);
		if(tour){
			setCurrentTour(tour.number);	
		}
	}, [data]);

	const dancers = useSelector(REDUX_DancerEvent.selector.arrayByKey((levelEvent) ? levelEvent.ID : 0));

	const newTour = () => {
		if (!levelEvent) { return; }
		setLoadingTour(true);
		auth.fetch("/api/tourgenerate/levelevent/" + levelEvent.ID + "/1").then(res => {
			if (res && res.data) {
				dispatch(REDUX_Tour.actions.setArrayByKey(levelEvent.ID, res.data));
				CRUDE_Protocol.read(auth, (data) => {
					if (data.length > 0) {
						dispatch(REDUX_Protocol.actions.setArrayByKey(levelEvent.ID, data));
					}
				}, 'model', 's-tour.number=ASC&s-number=ASC&w-tour.levelEvent.id=' + levelEvent.ID);
			} else if (res && !res.status) {
				message.error(res.message);
			}
			setLoadingTour(false);
		});
	};
	const newTourRandom = () => {
		if (!levelEvent) { return; }
		setLoadingTour(true);
		auth.fetch("/api/tourgenerate/levelevent/" + levelEvent.ID + "/2").then(res => {
			if (res && res.data) {
				dispatch(REDUX_Tour.actions.setArrayByKey(levelEvent.ID, res.data));
				CRUDE_Protocol.read(auth, (data) => {
					if (data.length > 0) {
						dispatch(REDUX_Protocol.actions.setArrayByKey(levelEvent.ID, data));
					}
				}, 'model', 's-tour.number=ASC&s-number=ASC&w-tour.levelEvent.id=' + levelEvent.ID);
			} else if (res && !res.status) {
				message.error(res.message);
			}
			setLoadingTour(false);
		});
	};
	const cleanTour = () => {
		if (!levelEvent) { return; }
		setLoadingTour(true);
		auth.fetch("/api/tourclear/levelevent/" + levelEvent.ID).then(res => {
			if (res && res.status) {
				dispatch(REDUX_Tour.actions.setArrayByKey(levelEvent.ID, []));
				dispatch(REDUX_Protocol.actions.setArrayByKey(levelEvent.ID, []));
			} else if (res && !res.status) {
				message.error(res.message);
			}
			setLoadingTour(false);
		});
	};
	const Tour = data.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.number}</Option>
		);
	});
	const dancerIcon = (number) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
			<defs>
				<linearGradient id="textgradient" x1="0%" x2="0%" y1="0%" y2="100%">
					<stop stopColor="#ccc" offset="0%"></stop>
					<stop stopColor="rgba(0, 0, 0, 0.65)" offset="100%"></stop>
				</linearGradient>
			</defs>
			<rect x="0" y="0" width="24" height="24" rx="8" ry="8" fill="url(#textgradient)"></rect>
			<text fontSize="12" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fill="white" stroke="none">{number}</text>
		</svg>
	);
	const dancerColumns = [
		{
			title: 'Тур',
			render: (text, record) => {
				return (record.tour.number);
			}
		},
		{
			title: 'Партнер',
			render: (text, record) => {
				return (<div style={{ display: "flex", justifyContent: "start" }}>
					<div style={{ paddingRight: "13px" }}>{dancerIcon(record.dancer.number)}</div>
					<div>{record.dancer.dancer.lastname + " " + record.dancer.dancer.firstname}</div>
				</div>);
			}
		},
		{
			title: 'Партнерша',
			render: (text, record) => {
				return (<div style={{ display: "flex", justifyContent: "start" }}>
					<div style={{ paddingRight: "13px" }}>{dancerIcon(record.judge.number)}</div>
					<div>{record.judge.dancer.lastname + " " + record.judge.dancer.firstname}</div>
				</div>);
			}
		}
	];
	const dancerFilters = [
		{
			name: "Партнер",
			value: item => item.dancer.dancer.lastname + " " + item.dancer.dancer.firstname,
			field: "dancer",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		{
			name: "Партнерша",
			value: item => item.judge.dancer.lastname + " " + item.judge.dancer.firstname,
			field: "judge",
			type: "string",
			sort: true,
			filter: true
		},
		// {
		// 	name: "Тур",
		// 	value: item => item.tour.number,
		// 	field: "Tour",
		// 	type: "string",
		// 	sort: true,
		// 	filter: true,
		// 	sorted: "ASC"
		// }
	];
	const badge = (statistic) => {
		if (!statistic.good) {
			return <CheckCircleOutlined style={{ color: "red" }} />
		} else {
			if (!statistic.check) {
				return <CheckCircleOutlined style={{ color: "orange" }} />
			} else {
				return <CheckCircleOutlined style={{ color: "green" }} />
			}
		}
	}

	const protocolColumns = [
		{
			title: 'Номер',
			render: (text, record) => {
				return (record.tour.number + "-" + record.number);
			}
		},
		{
			title: 'Тур',
			render: (text, record) => {
				return (record.tour.number);
			}
		},
		{
			title: 'Судья',
			render: (text, record) => {
				var judge = record.judge.dancer;
				return (judge.lastname + " " + judge.firstname);
			}
		},
		{
			title: 'Значения',
			render: (text, record) => {

				if (record.protocolParams) {
					for (let j = 0; j < record.protocolParams.length; j++) {
						const p = record.protocolParams[j];
						if (p.value) {
							return <Tooltip title="Есть значения">
								<IssuesCloseOutlined style={{ color: "green" }} />
							</Tooltip>

						}
					}
				}
				return <></>
			}
		},
		{
			title: 'Проверка',
			render: (text, record) => {
				var statistic = stat(record, protocolCriterionParamsCount(record, protocolCriterionParams))
				return (
					<span style={{ fontSize: "12px", marginRight: "10px" }}>
						{badge(statistic)}
					</span>)
			}
		},
		{
			title: 'Готово',
			render: (text, record) => {
				return (
					<span style={{ fontSize: "12px", marginRight: "10px" }}>
						{record.completed && <CheckOutlined style={{ color: "green" }} />}
						{!record.completed && <CloseOutlined style={{ color: "red" }} />}
					</span>)
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<BtnProtocol auth={auth} index={index + 1} object={record} />),
		},
	];
	const protocolFilters = [
		{
			name: "Номер",
			value: item => item.number,
			field: "number",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		// {
		// 	name: "Тур",
		// 	value: item => item.tour.number,
		// 	field: "Tour",
		// 	type: "string",
		// 	sort: true,
		// 	filter: true,
		// 	sorted: "ASC"
		// },
		{
			name: "Судья",
			value: item => item.judge.dancer.lastname + " " + item.judge.dancer.firstname,
			field: "judge",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Значения",
			value: item => {
				if (item.protocolParams) {
					for (let j = 0; j < item.protocolParams.length; j++) {
						const p = item.protocolParams[j];
						if (p.value) {
							return "Есть"
						}
					}
				}
				return "Нет"
			},
			field: "data",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Проверка",
			value: item => {
				var statistic = stat(item, protocolCriterionParamsCount(item, protocolCriterionParams))
				if (!statistic.good) {
					return "Ошибки заполнения"
				} else {
					if (!statistic.check) {
						return "Одинаковые оценки"
					} else {
						return "Корректно"
					}
				}
			},
			field: "check",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Готово",
			value: item => {
				if (item.completed) {
					return "Да"
				}
				return "Нет"
			},
			field: "completed",
			type: "string",
			sort: true,
			filter: true
		}
	];
	const star = (record, goNextEvent) => {
		const update = (done, checked) => {
			CRUDE_DancerEvent.update(props.auth, (data) => {
				dispatch(REDUX_DancerEvent.actions.updateArrayByKey(levelEvent.ID, [data]));
			}, { ...record, goNextEvent: checked }, dispatch);
			Eventresultm();
			Eventresultw();
		};
		return (
			<CheckableTag style={{ backgroundColor: "transparent" }}
				checked={goNextEvent}
				onChange={checked => update(record, checked)}>
				{goNextEvent && <StarOutlined style={{ color: "orange", fontSize: "16px" }} />}
				{!goNextEvent && <StarOutlined style={{ fontSize: "16px" }} />}
			</CheckableTag>
		);
	};
	const buildColumnsDancerResult = (dancerResult) => {
		var head = [
			{
				title: 'Танцор',
				width: 180,
				fixed: 'left',
				render: (text, record) => {
					return (record.DancerEvent.dancer.lastname + " " + record.DancerEvent.dancer.firstname);
				}
			},
			{
				title: 'Номер',
				width: 100,
				fixed: 'left',
				render: (text, record) => {
					return (<div style={{ display: "flex", justifyContent: "start" }}>
						<div style={{ paddingRight: "13px" }}>{dancerIcon(record.DancerEvent.number)}</div>
					</div>);
				}
			},
		];
		var body = [

		];
		if (dancerResult && dancerResult.length > 0) {
			dancerResult[0].TableSort.forEach((element, idx) => {
				body.push({
					title: element.Name,// ()=>(<PlusCircleOutlined style={{ color: "green" }} />),
					width: 100,
					render: (text, record) => {
						return (Math.round(record.TableSort[idx].Value * 100) / 100);
					}
				});
			});
		}
		var tail = [
		];
		return [...head, ...body, ...tail];
	};
	const Eventresultm = () => {
		if (!levelEvent) return;
		auth.fetch("/api/levelevent/" + levelEvent.ID + "/1/result").then(res => {
			if (res && res.data) {
				setDancerResultM(res.data.filter(e => e.DancerEvent.dancer.genderID === 1));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	const Eventresultw = () => {
		if (!levelEvent) return;
		auth.fetch("/api/levelevent/" + levelEvent.ID + "/2/result").then(res => {
			if (res && res.data) {
				setDancerResultW(res.data.filter(e => e.DancerEvent.dancer.genderID === 2));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	const printMenu = (
		<Menu>
			<Menu.Item key="1" icon={<PrinterOutlined />}>
				{/* <a target="_blank" rel="noopener noreferrer" href={"/Event/" + ((Event) ? Event.ID : 0) + "/printdataprotocols"}>Заполненные протоколы</a> */}
			</Menu.Item>
		</Menu>
	);
	const onChangeCurrentTour = (tourNumber) => {
		setCurrentTour(tourNumber);
	}
	const manProtocols = protocols.filter(i => i.dancer.dancer.genderID === 1);
	return (
		<>
			<Card size="small" className={classes.block} style={{ width: "100%", marginBottom: "4px" }}>
				<Row justify="start">
					<Col flex="auto">
						<Select value={(levelEvent) ? levelEvent.ID : undefined} style={{ width: "100%" }} onChange={levelEventChange}>
							{levelEvents}
						</Select>
					</Col>
				</Row>
				<Row justify="start">
					<Col style={{ paddingTop: "10px" }}>
						<Dropdown overlay={<Menu>
							<Menu.Item key="1" onClick={newTour}>
								В порядке нумерации танцоров
    						</Menu.Item>
							<Menu.Item key="2" onClick={newTourRandom}>
								Случайное распределение
    						</Menu.Item>
						</Menu>}>
							<Button loading={loadingTour}>
								Формировать туры <DownOutlined />
							</Button>
						</Dropdown>
						{/* <Button loading={loadingTour} onClick={newTour}>Формировать туры</Button> */}
					</Col>
					<Col style={{ paddingLeft: "10px", paddingTop: "10px" }}>
						<Button loading={loadingTour} onClick={cleanTour}>Удалить туры</Button>
					</Col>
				</Row>
			</Card>
			<Card size="small" className={classes.block} style={{ width: "100%" }}>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Туры" key="1">
						<Table loading={loading} pagination={false} columns={columns} dataSource={data} locale={{ emptyText: <Empty description={"Нет туров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
					</TabPane>
					<TabPane tab="Танцоры в турах" key="2">
						<Filtered title={"Танцоры тура"} noPagination={true} /*quickFilter={true}*/ filters={dancerFilters} data={manProtocols.filter(i => i.tour.number == currentTour)} content={items => (
							<Card size="small" className={classes.block} style={{ width: "100%" }}>
								<Table loading={loading} pagination={false} columns={dancerColumns} dataSource={items} locale={{ emptyText: <Empty description={"Нет танцоров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
							</Card>
						)}
							addition={
								(
									<Row justify="start">
										<span>Номер тура:</span><Pagination size="small" current={currentTour} onChange={onChangeCurrentTour} pageSize={1} total={data.length} />
									</Row>
								)
							} />
					</TabPane>
					<TabPane tab="Протоколы тура" key="3">
						<Filtered title={"Танцоры тура"} noPagination={true} noPrint={true}/*quickFilter={true}*/ filters={protocolFilters} data={protocols.filter(i => i.tour.number == currentTour)} content={items => (
							<Card size="small" className={classes.block} style={{ width: "100%" }}>
								<Table pagination={false} columns={protocolColumns} dataSource={items} locale={{ emptyText: <Empty description={"Нет протоколов"}></Empty> }} size="small" rowKey={(r) => r.ID} />
							</Card>
						)}
							addition={
								(
									<Row justify="start">
										<span>Номер тура:</span><Pagination size="small" current={currentTour} onChange={onChangeCurrentTour} pageSize={1} total={data.length} />
									</Row>
								)
							} />
					</TabPane>
					<TabPane tab="Итоги Мужчины" key="4">
						<Row gutter={[5, 5]}>
							<Col span={24}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<div>
										<Button onClick={Eventresultm} style={{ marginBottom: "10px" }}>Обновить</Button>
									</div>
									<div>
										<Dropdown.Button style={{ marginBottom: "10px" }} overlay={<Menu>
											<Menu.Item key="1" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/1/printresult"}>Результаты</a>
											</Menu.Item>
											<Menu.Item key="3" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/api/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/1/printautoupdateresult"}>Результаты с автообновлением</a>
											</Menu.Item>
										</Menu>}>
											<a target="_blank" rel="noopener noreferrer" href={"/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/1/printresult"}><PrinterOutlined /></a>
										</Dropdown.Button>
									</div>
								</div>
							</Col>
						</Row>
						<Table pagination={false} scroll={{ x: true }} columns={buildColumnsDancerResult(dancerResultM)} dataSource={dancerResultM} locale={{ emptyText: <Empty description={"Нет итогов"}></Empty> }} size="small" rowKey={(r) => r.ID + "M"} />
					</TabPane>
					<TabPane tab="Итоги Женщины" key="5">
						<Row gutter={[5, 5]}>
							<Col span={24}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<div>
										<Button onClick={Eventresultw} style={{ marginBottom: "10px" }}>Обновить</Button>
									</div>
									<div>
										<Dropdown.Button style={{ marginBottom: "10px" }} overlay={<Menu>
											<Menu.Item key="1" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/2/printresult"}>Результаты</a>
											</Menu.Item>
											<Menu.Item key="3" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/api/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/2/printautoupdateresult"}>Результаты с автообновлением</a>
											</Menu.Item>
										</Menu>}>
											<a target="_blank" rel="noopener noreferrer" href={"/levelevent/" + ((levelEvent) ? levelEvent.ID : 0) + "/2/printresult"}><PrinterOutlined /></a>
										</Dropdown.Button>
									</div>
								</div>
							</Col>
						</Row>
						<Table pagination={false} scroll={{ x: true }} columns={buildColumnsDancerResult(dancerResultW)} dataSource={dancerResultW} locale={{ emptyText: <Empty description={"Нет итогов"}></Empty> }} size="small" rowKey={(r) => r.ID + "W"} />
					</TabPane>
				</Tabs>

			</Card>
		</>
	);
}
