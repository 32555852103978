import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col, Select, Table, Empty, Button, Divider } from 'antd';

import 'moment/locale/ru';
import { createUseStyles } from 'react-jss'

import REDUX_Template from '../../objects/Template/reducer';
import CRUDE_Template from '../../objects/Template/crude';

import REDUX_LevelDancer from '../../objects/LevelDancer/reducer'
import CRUDE_LevelDancer from '../../objects/LevelDancer/crude'

import REDUX_Criterion from '../../objects/Criterion/reducer';
import CRUDE_Criterion from '../../objects/Criterion/crude';

import REDUX_CriterionParams from '../../objects/CriterionParams/reducer';
import CRUDE_CriterionParams from '../../objects/CriterionParams/crude';

import REDUX_CriterionLevel from '../../objects/CriterionLevel/reducer';
import CRUDE_CriterionLevel from '../../objects/CriterionLevel/crude';
import REDUX_CriterionParamsPriority from '../../objects/CriterionParamsPriority/reducer';
import CRUDE_CriterionParamsPriority from '../../objects/CriterionParamsPriority/crude';
import REDUX_CriterionTemplate from '../../objects/CriterionTemplate/reducer'
import CRUDE_CriterionTemplate from '../../objects/CriterionTemplate/crude'

const { Option } = Select;

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})


export default function CriteriontemplatePriority(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	//-------------------------------------------------------------------------------------------
	//	TEMPLATE
	//-------------------------------------------------------------------------------------------
	const templates = useSelector(REDUX_Template.selector.array);
	const [template, setTemplate] = useState((templates.length <= 0) ? undefined : templates[0]);
	const templateChange = (value) => {
		setTemplate(templates.find(item => item.ID === value));
	};
	useEffect(() => {
		CRUDE_Template.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_Template.actions.setArray(data));
			}
		}, 'none'); // model
	}, [auth, dispatch]);
	useEffect(() => {
		if (!template) {
			setTemplate((templates.length <= 0) ? undefined : templates[0]);
		}
	}, [templates, template]);
	const optionsTemplates = templates.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.name}</Option>
		);
	});
	//-------------------------------------------------------------------------------------------
	//	LEVEL DANCER
	//-------------------------------------------------------------------------------------------
	const levels = useSelector(REDUX_LevelDancer.selector.array);
	const [level, setLevel] = useState((levels.length <= 0) ? undefined : levels[0]);
	const levelChange = (value) => {
		setLevel(levels.find(item => item.ID === value));
	};
	useEffect(() => {
		CRUDE_LevelDancer.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_LevelDancer.actions.setArray(data));
			}
		}, 'model', 's-id=ASC');
	}, [auth, dispatch]);
	useEffect(() => {
		if (!level) {
			setLevel((levels.length <= 0) ? undefined : levels[0]);
		}
	}, [levels, level]);
	const optionsLevel = levels.map(i => (
		<Option key={i.ID} value={i.ID}>{i.name}</Option>
	));
	//-------------------------------------------------------------------------------------------
	//	CriterionParams
	//-------------------------------------------------------------------------------------------
	const criterionparams = useSelector(REDUX_CriterionParams.selector.arrayByKey((level && template) ? "L" + level.ID + "T" + template.ID : undefined));
	useEffect(() => {
		if (template && level) {
			CRUDE_CriterionParams.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_CriterionParams.actions.setArrayByKey("L" + level.ID + "T" + template.ID, data));
				}
			}, 'model', 's-id=ASC&w-criterion.criterionLevel.levelDancer.ID=' + level.ID + '&w-criterion.criterionLevel.template.ID=' + template.ID + '&w-criterion.criterionTemplate.template.ID=' + template.ID);
		}
	}, [template, level, auth, dispatch]);
	//-------------------------------------------------------------------------------------------
	//	Criterion
	//-------------------------------------------------------------------------------------------
	const criterions = useSelector(REDUX_Criterion.selector.arrayByKey((template) ? template.ID : undefined));
	useEffect(() => {
		if (template) {
			CRUDE_Criterion.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_Criterion.actions.setArrayByKey(template.ID, data));
				}
			}, 'model', 'w-criterionTemplate.template.id=' + template.ID);
		}
	}, [template, auth, dispatch]);
	//-------------------------------------------------------------------------------------------
	//	CriterionTemplate
	//-------------------------------------------------------------------------------------------
	const criteriontemplates = useSelector(REDUX_CriterionTemplate.selector.arrayByKey((template) ? template.ID : undefined));
	useEffect(() => {
		if (template) {
			CRUDE_CriterionTemplate.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_CriterionTemplate.actions.setArrayByKey(template.ID, data));
				}
			}, 'model', 's-criterion.name=ASC&w-template.id=' + template.ID);
		}
	}, [template, auth, dispatch]);
	//-------------------------------------------------------------------------------------------
	//	CriterionLevel & CriterionParamsPriority
	//-------------------------------------------------------------------------------------------
	const criterionlevel = useSelector(REDUX_CriterionLevel.selector.arrayByKey((level && template) ? "L" + level.ID + "T" + template.ID : undefined));
	const criterionparamspriority = useSelector(REDUX_CriterionParamsPriority.selector.arrayByKey((level && template) ? "L" + level.ID + "T" + template.ID : undefined));
	useEffect(() => {
		if (level && template) {
			setLoading(true);
			CRUDE_CriterionLevel.read(auth, (data) => {
				setLoading(false);
				if (data.length > 0) {
					dispatch(REDUX_CriterionLevel.actions.setArrayByKey("L" + level.ID + "T" + template.ID, data));
				}
			}, 'model', 'w-levelDancer.id=' + level.ID + '&w-template.id=' + template.ID);
			CRUDE_CriterionParamsPriority.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_CriterionParamsPriority.actions.setArrayByKey("L" + level.ID + "T" + template.ID, data));
				}
			}, 'model', 'w-levelDancer.id=' + level.ID + '&w-template.id=' + template.ID);
		}
	}, [template, level, auth, dispatch]);
	//-------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------
	const nextDigitValue = (val, list) => {
		if (val.priority >= list.length) {
			return 1;
		}
		switch (val.priority) {
			case null:
				return 1;
			case undefined:
				return 1;
			case 0:
				return 1;
			case list.length:
				return 1;
			default:
				return val.priority + 1;
		}
	};
	const criterionParamsPriorityClickCreate = (criterionparam, level, length) => {
		CRUDE_CriterionParamsPriority.create(auth, (data) => {
			dispatch(REDUX_CriterionParamsPriority.actions.updateArrayByKey("L" + level.ID + "T" + template.ID, [data]));
		}, {
			criterionParamID: criterionparam.ID,
			templateID: template.ID,
			levelDancerID: level.ID,
			priority: length || 1
		});
	};
	const criterionParamsPriorityClickUpdate = (crlevel, value) => {
		if (value === undefined) {
			CRUDE_CriterionParamsPriority.destroy(auth, (data) => {
				dispatch(REDUX_CriterionParamsPriority.actions.setArrayByKey("L" + level.ID + "T" + template.ID, criterionparamspriority.filter(item => item.ID !== crlevel.ID)));
			}, crlevel.ID);
			return
		}
		CRUDE_CriterionParamsPriority.update(auth, (data) => {
			dispatch(REDUX_CriterionParamsPriority.actions.updateArrayByKey("L" + level.ID + "T" + template.ID, [data]));
		}, { ...crlevel, priority: value });
	};
	const criterionlevelClickCreate = (criterion, length) => {
		CRUDE_CriterionLevel.create(auth, (data) => {
			dispatch(REDUX_CriterionLevel.actions.updateArrayByKey("L" + level.ID + "T" + template.ID, [data]));
			CRUDE_CriterionParams.read(auth, (data) => {
				if (data.length > 0) {
					dispatch(REDUX_CriterionParams.actions.setArrayByKey("L" + level.ID + "T" + template.ID, data));
				}
			}, 'model', 's-id=ASC&w-criterion.criterionLevel.levelDancer.ID=' + level.ID + '&w-criterion.criterionLevel.template.ID=' + template.ID + '&w-criterion.criterionTemplate.template.ID=' + template.ID);
		}, {
			criterionID: criterion.ID,
			templateID: template.ID,
			levelDancerID: level.ID,
			priority: length || 1
		});
	};
	const criterionlevelClickUpdate = (crlevel, list, value) => {
		if (value === undefined) {
			CRUDE_CriterionLevel.destroy(auth, (data) => {
				dispatch(REDUX_CriterionLevel.actions.setArrayByKey("L" + level.ID + "T" + template.ID, criterionlevel.filter(item => item.ID !== crlevel.ID)));
				CRUDE_CriterionParams.read(auth, (data) => {
						dispatch(REDUX_CriterionParams.actions.setArrayByKey("L" + level.ID + "T" + template.ID, data));
				}, 'model', 's-id=ASC&w-criterion.criterionLevel.levelDancer.ID=' + level.ID + '&w-criterion.criterionLevel.template.ID=' + template.ID + '&w-criterion.criterionTemplate.template.ID=' + template.ID);
			}, crlevel.ID);
			return
		}
		CRUDE_CriterionLevel.update(auth, (data) => {
			dispatch(REDUX_CriterionLevel.actions.updateArrayByKey("L" + level.ID + "T" + template.ID, [data]));
		}, { ...crlevel, priority: value });
	};
	const inline = () => {
		return {
			title: "Приоритет",
			key: 'L1',
			render: (text, record) => {
				var cl = criterionlevel.find(item => item.criterion.ID === record.ID);
				if (!cl) {
					return (
						<Button onClick={(e) => criterionlevelClickCreate(record, criterionlevel.length+1)} style={{ border: "1px dashed rgb(0,0,0,0.2)", color: "rgb(0,0,0,0.4)", width: "100%" }}>
							+
						</Button>
					);
				}
				
				return (
					<Select defaultValue={cl.priority} style={{ width: "100%" }} allowClear
						onChange={(value) => criterionlevelClickUpdate(cl, criterionlevel, value)}>
						{Array.apply(null, { length: criterionlevel.length }).map((item, idx) => <Option key={idx} value={idx + 1}>{idx + 1}</Option>)}
					</Select>
				)
			},
		}
	};
	const columnsCriterionLevel = [
		{
			title: 'Критерий',
			key: 'name',
			render: (text, record) => record.name,
		},
		inline()
	];
	const columnsCriterionParamsPriority = [
		{
			title: 'Параметр',
			key: 'name',
			render: (text, record) => record.name,
		},
		{
			title: 'Критерий',
			key: 'criterion',
			render: (text, record) => record.criterion.name,
		},
		{
			title: "Приоритет",
			key: "priority",
			render: (text, record) => {
				var cl = criterionparamspriority.find(item => item.criterionParam.ID === record.ID);
				if (!cl) {
					return (
						<Button onClick={(e) => criterionParamsPriorityClickCreate(record, level, criterionparamspriority.length+1)} style={{ border: "1px dashed rgb(0,0,0,0.2)", color: "rgb(0,0,0,0.4)", width: "100%" }}>
							+
						</Button>
					);
				}
				return (
					<Select defaultValue={cl.priority} style={{ width: "100%" }} allowClear
						onChange={(value) => criterionParamsPriorityClickUpdate(cl, value)}>
						{Array.apply(null, { length: criterionparamspriority.length }).map((item, idx) => <Option key={idx} value={idx + 1}>{idx + 1}</Option>)}
					</Select>
				)
			},
		}
	];
	return (
		<>
			<Row gutter={[5, 5]}>
				<Col span={24}>
					<Card size="small">
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<div style={{ flex: "auto" }}>
								<Row justify="start">
									<Col flex="50%">
										<Select value={(template) ? template.ID : undefined} style={{ width: "100%" }} onChange={templateChange}>
											{optionsTemplates}
										</Select>
									</Col>
									<Col flex="50%">
										<Select value={(level) ? level.ID : undefined} style={{ width: "100%", paddingLeft: "10px" }} onChange={levelChange}>
											{optionsLevel}
										</Select>
									</Col>
								</Row>
							</div>
							<div justify="end">

							</div>
						</div>
					</Card>
				</Col>
			</Row>
			<Card size="small" style={{ width: "100%" }}>
				{/* CriterionLevel
			CriterionParamsPriority */}
				<div>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Приоритет критериев в шаблоне
                    </Divider>
					<Table loading={loading} pagination={false} columns={columnsCriterionLevel} dataSource={criterions} locale={{ emptyText: <Empty description={"Нет данных"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</div>
				<div style={{ paddingTop: "25px" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Приоритет параметров в оценке результатов
                    </Divider>
					<Table loading={loading} pagination={false} columns={columnsCriterionParamsPriority} dataSource={criterionparams} locale={{ emptyText: <Empty description={"Нет данных"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</div>
			</Card>
		</>
	);
}
