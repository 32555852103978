import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';

import logger from 'redux-logger'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  return {
    ...createStore(rootReducer, 
      // composeEnhancers(applyMiddleware(logger))
      //applyMiddleware(logger)
      applyMiddleware(thunk)
      )
  }
};

export default configureStore;