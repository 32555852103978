export const POST = (auth, url, object, callback, error) => { 
    auth.fetch(url, {
        method: 'POST',
        body: JSON.stringify(object)
    }).then(res => {
        if (res && res.status===true) {
            if (callback) {
                callback(res.data);
            }
        } else if(res && res.status===false) {
            if(error) {
                error(res, "fail");
            }    
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        }
    });
};

export const GET = (auth, url, callback, error) => {
    auth.fetch(url).then(res => {
        if (res && res.status===true && res.data) {
            if (callback) {
                callback(res.data);
            }
        } else if(res && res.status===false) {
            if(error) {
                error(res, "fail");
            }    
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        }
    });
};