import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Modal, Select, Empty } from 'antd';

import {
	ExclamationCircleOutlined} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Event from '../../objects/Event/reducer';
import CRUDE_Event from '../../objects/Event/crude';

import REDUX_LevelEvent from '../../objects/LevelEvent/reducer';
import CRUDE_LevelEvent from '../../objects/LevelEvent/crude';

import NewLevelEvent from '../../forms/NewLevelEvent'
import UpdateLevelEvent from '../../forms/UpdateLevelEvent'

// import JudgeEvent from '../../forms/JudgeEvent'
import DancerEvent from '../../forms/DancerEvent'

const { confirm } = Modal;
const { Option } = Select;

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const levelEvents = useSelector(REDUX_LevelEvent.selector.arrayByKey(object.event.ID));
	const odelete = () => {
		CRUDE_LevelEvent.destroy(auth, () => {
			dispatch(REDUX_LevelEvent.actions.setArrayByKey(object.event.ID, levelEvents.filter(item => item.ID !== object.ID)));
		}, props.object.ID);
	};
	const update = (done, values, unlock) => {
		CRUDE_LevelEvent.update(props.auth, (data) => {
			dispatch(REDUX_LevelEvent.actions.updateArrayByKey(object.event.ID, [data]));
			done();
		}, { ...props.object, ...values });
		if (unlock)
			unlock();
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить группу" }} object={object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateLevelEvent} document={"query-update/levelevent"} />
			<Action {...props} readonly={true} modal={{ title: "Танцоры группы", width: "65%" }} object={object} mode={"MenuItem"} action={empty} title={"Танцоры группы"} form={DancerEvent} document={"dancerevent"} />
			<Menu.Item><a target="_blank" rel="noopener noreferrer" href={"/levelevent/" + ((object) ? object.ID : 0) + "/printresultevent"}>Результаты</a></Menu.Item>
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{color: "red"}} onClick={()=> confirm({
				title: 'Вы действительно хотите удалить мероприятие?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление мероприятия приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CommonGroups(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const events = useSelector(REDUX_Event.selector.array);
	const context = () => {
		const e = localStorage.getItem('event');
		var o = events.find(item => item.ID.toString() === e);
		return o;
	};
	const [event, setEvent] = useState((events.length <= 0) ? undefined : context());

	const eventChange = (value) => {
		var e = events.find(item => item.ID === value);
		localStorage.setItem('event', e.ID);
		setEvent(e);
	};
	useEffect(() => {
		CRUDE_Event.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Event.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);
	useEffect(() => {
		const e = localStorage.getItem('event');
		if(e && events && events.length && !event){
			var o = context();
			setEvent((events.length <= 0) ? undefined : o);
		}
	}, [events, event]);
	const optionsEvents = events.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.name}</Option>
		);
	});
	useEffect(() => {
		setLoading(true);
		CRUDE_LevelEvent.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_LevelEvent.actions.setArrayByKey((event)?event.ID:0, data));
			}
		}, 'model',"s-id=ASC&w-event.id="+((event)?event.ID:0));
	}, [event,auth, dispatch]);
	const data = useSelector(REDUX_LevelEvent.selector.arrayByKey((event)?event.ID:0));
	const update = (res) => {
		dispatch(REDUX_LevelEvent.actions.updateArrayByKey((event)?event.ID:0,[res.data]));
	};
	const filters = [
		{
			name: "Уровень",
			value: item => item.level.name,
			field: "level",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		{
			name: 'Нумерация',
			value: item => item.number,
			field: "number",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Шаблон",
			value: item => item.template.name,
			field: "template",
			type: "string",
			sort: true,
			filter: true
		}
	];
	const columns = [
		{
			title: 'Уровень',
			render: (text, record) => {
				return (record.level.name);
			}
		},
		{
			title: 'Нумерация',
			render: (text, record) => {
				return (record.number);
			}
		},
		{
			title: 'Шаблон',
			render: (text, record) => {
				return (record.template.name);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	return (
		<>
			<Filtered title={"Группы"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Группы
                    </Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет групп"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<>
						<Row justify="start">
							<Col flex="auto">
								<Select value={(event) ? event.ID : undefined} style={{ width: "100%" }} onChange={eventChange}>
									{optionsEvents}
								</Select>
							</Col>
							<Col flex="50px" style={{paddingLeft: "10px"}}>
								<Action {...props} object={event} callback={update} title={"Новая группа"} form={NewLevelEvent} document={"query-create/levelEvent"} />
							</Col>
						</Row>
					</>
					)
				} />
		</>
	);
}
