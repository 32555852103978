import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Row, Col, Typography } from 'antd';

import 'moment/locale/ru';

import REDUX_Protocol from '../../objects/Protocol/reducer'
import CRUDE_Protocol from '../../objects/Protocol/crude'

import CRUDE_ProtocolParams from '../../objects/ProtocolParams/crude'

import {
    MinusOutlined,
    PlusOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

const { Option } = Select;

const statPrepair = (protocol, s) => {
    var s = s || {};
    protocol.protocolParams.forEach(item => {
        if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
        s[item.criterionParams.criterionID].value += (!!item.value) ? item.value : 0;
        s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

        if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
            s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {}, good: true };
        }
        var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
        if (!countValue) {
            s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
        } else {
            s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
        }

        if (protocol.tour.levelEvent.maxCountEqualScores && item.value && s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.tour.levelEvent.maxCountEqualScores) {
            s[item.criterionParams.criterionID][item.criterionParamsID].good = false;
            s[item.criterionParams.criterionID].good = false;
        }
    });
    return s;
};
const protocolCriterionParamsCount = (protocol, protocolCriterionParams) => {
    var count = 0;
    protocolCriterionParams.forEach(protocolCriterian => {
        if (protocol.dancer.dancer.genderID === 1) {
            if (protocolCriterian.ParamsMan && protocolCriterian.ParamsMan.length) {
                    count++;
            }
        } else {
            if (protocolCriterian.ParamsWoman && protocolCriterian.ParamsWoman.length) {
                    count++;
            }
        }
    });
    return count;
};
const stat = (protocol, criterionCount) => {
    criterionCount = criterionCount || 0;
    var count = 0;
    var s = {};
    if (!protocol) return;

    var s = statPrepair(protocol);

    if (protocol.EditedProtocols) {
        protocol.EditedProtocols.forEach(p => {
            s = statPrepair(p, s);
        });
    }

    var good = true;
    var check = true;
    if (Object.keys(s).length !== criterionCount) {
        good = false;
    } else {
        Object.keys(s).forEach(i => {
            if (!s[i].good) {
                good = false;
            }
        });
    }

    Object.keys(s).forEach(i => {
        if (!s[i].check) {
            check = false;
        }
    });

    return {
        ...s,
        good: good,
        check: check
    }
};
const mergeProtocolParamValue = (protocol, protocolParam, value) => {
    var ptcl = {
        ...protocol,
        protocolParams: protocol.protocolParams.map(p => {
            if (p.ID === protocolParam.ID) {
                return { ...p, value: value }
            }
            return p;
        })
    };
    /* УДАЛИТЬ И ПРОТЕСТИРОВАТЬ - begin */
    var f = protocol.protocolParams.find(p => p.ID !== protocolParam.ID);
    if (!f) {
        ptcl = {
            ...protocol,
            protocolParams: protocol.protocolParams.push(protocolParam)
        };
    }
    /* УДАЛИТЬ И ПРОТЕСТИРОВАТЬ - end */
    return ptcl;
};

const setCurrentProtocolParamValue = (dispatch, protocol, protocolParam, value) => {
    var ptcl = mergeProtocolParamValue(protocol, protocolParam, value);
    dispatch(REDUX_Protocol.actions.updateArrayByKey(protocol.tour.levelEventID, [ptcl]));
    return ptcl;
};

function Value({ auth, value, protocol, protocolDancerParam, criterion, protocolCriterionParams }) {
    const dispatch = useDispatch();
    const change = (value, protocolDancerParam) => {
        if (!value) value = 0;
        var object = { ID: protocolDancerParam.ID, value: value };
        CRUDE_ProtocolParams.update(auth, () => {
            var ptcl = setCurrentProtocolParamValue(dispatch, protocol, protocolDancerParam, value);
            dispatch({ type: 'stat', payload: stat(ptcl, protocolCriterionParamsCount(ptcl, protocolCriterionParams)) });
        }, object);
    };

    var arr = [];
    var step = criterion.step || 1;
    step = Math.round(step * 100) / 100
    var val = step;
    while (val <= criterion.maxScoreValue) {
        arr.push(val);
        val += step;
        val = Math.round(val * 100) / 100
    }

    return (
        <>
            <Select style={{ width: "100%" }} bordered={false} allowClear
                value={(value) ? (value) : undefined}
                onChange={(value) => change(value, protocolDancerParam, criterion)}>
                {arr.map((item, idx) => <Option key={idx} value={item}>{item}</Option>)}
                {/* Array.apply(null, {length: criterion.maxScoreValue}).map(Number.call, Number) */}
            </Select>
        </>
    );


}
export default function Protocol(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;

    const statistic = useSelector(state => state.mobile.stat);
    const [protocolCriterionParams, setProtocolCriterionParams] = useState([]);

    // const ProtocolParamsValue = useSelector(state => state.home.ProtocolParamsValue);
    // useEffect(() => {
    //     if(ProtocolParamsValue && object.ID === ProtocolParamsValue.protocolID){
    //         var ptcl = setCurrentProtocolParamValue(dispatch, object, ProtocolParamsValue, ProtocolParamsValue.value);
    //         dispatch({ type: 'stat', payload: stat(ptcl, protocolCriterionParamsCount(ptcl, protocolCriterionParams)) });
    //     }
    // }, [ProtocolParamsValue, dispatch]);

    useEffect(() => {
        auth.fetch("/api/levelevent/" + object.tour.levelEventID + "/protocolcriterionparams").then(res => {
            if (res && res.data) {
                setProtocolCriterionParams(res.data);

                dispatch({ type: 'stat', payload: stat(object, protocolCriterionParamsCount(object, res.data)) });
            }
        });
    }, [object, auth, dispatch]);
    useEffect(() => {
        dispatch({ type: 'stat', payload: stat(object, protocolCriterionParamsCount(object, protocolCriterionParams)) });
    }, [object, protocolCriterionParams, dispatch]);

    // const protocol = useSelector(REDUX_Protocol.selector.arrayByKey(object.ID));
    // useEffect(() => {
    //     CRUDE_Protocol.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_Protocol.actions.setArrayByKey(object.ID, data));
    //         }
    //     }, 'model', 'w-bunch.id='+object.ID);
    // }, []);
    const headerLine1 = () => {
        var jsx = [];
        protocolCriterionParams.forEach(current => {
            if (object.dancer.dancer.genderID === 1) {
                if (current.ParamsMan) {
                    jsx.push(
                        <td key={current.Criterion.ID}
                            colSpan={current.ParamsMan.length}
                            style={{ fontWeight: "600" }}>{current.Criterion.name}
                        </td>);
                }
            } else {
                if (current.ParamsWoman) {
                    jsx.push(
                        <td key={current.Criterion.ID}
                            colSpan={current.ParamsWoman.length}
                            style={{ fontWeight: "600" }}>{current.Criterion.name}
                        </td>
                    );
                }
            }
        });
        return jsx;
    };
    const headerLine2 = () => {
        var jsx = [];
        protocolCriterionParams.forEach(protocolCriterian => {
            if (object.dancer.dancer.genderID === 1) {
                if (protocolCriterian.ParamsMan) {
                    protocolCriterian.ParamsMan.forEach(param => {
                        jsx.push(<td key={param.ID} style={{ width: "100px" }}>{param.name}</td>);
                    });
                }
            } else {
                if (protocolCriterian.ParamsWoman) {
                    protocolCriterian.ParamsWoman.forEach(param => {
                        jsx.push(<td key={param.ID} style={{ width: "100px" }}>{param.name}</td>);
                    });
                }
            }
        });
        return (jsx);
    };
    const bodyOuter = () => {
        return (
            <tr key={object.ID}>
                <td style={{ backgroundColor: "aqua" }}>{object.dancer.number}</td>
                <td>
                    <div style={{ textAlign: "left", paddingRight: "10px", minWidth: "35px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>{object.protocolParams.map(item => item.value).reduce(reducer)}</div>
                            </div>
                        </div>
                    </div>

                </td>
                {bodyInner()}
            </tr>
        );
    };
    const findParam = (protocol, p) => {
        var res = undefined;
        protocol.protocolParams.forEach(param => {
            if (param.criterionParamsID === p.ID) {
                res = param;
                return;
            }
        });
        return res;
    };
    const bodyInner = () => {
        var jsx = [];
        protocolCriterionParams.forEach(protocolCriterian => {
            if (object.dancer.dancer.genderID === 1) {
                if (protocolCriterian.ParamsMan) {
                    protocolCriterian.ParamsMan.forEach(param => {
                        var pp = findParam(object, param);
                        jsx.push(<td key={param.ID}>
                            <Value auth={auth} value={((pp) ? pp.value : undefined)} protocol={object} protocolDancerParam={pp} criterion={param} protocolCriterionParams={protocolCriterionParams}></Value>
                        </td>);
                    });
                }
            } else {
                if (protocolCriterian.ParamsWoman) {
                    protocolCriterian.ParamsWoman.forEach(param => {
                        var pp = findParam(object, param);
                        jsx.push(<td key={param.ID}>
                            <Value auth={auth} value={((pp) ? pp.value : undefined)} protocol={object} protocolDancerParam={pp} criterion={param} protocolCriterionParams={protocolCriterionParams}></Value>
                        </td>);
                    });
                }
            }
        });
        return (jsx);
    };

    const sumPlus = (arr) => {
        var acc = 0;
        arr.forEach(item => (item > 0) ? acc += 1 : acc);
        return acc;
    }
    const sumMinus = (arr) => {
        var acc = 0;
        arr.forEach(item => (item < 0) ? acc += 1 : acc);
        return acc;
    }
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const badge = (statistic) => {
        if (!statistic.good) {
            return <CheckCircleOutlined style={{ color: "red" }} />
        } else {
            if (!statistic.check) {
                return <CheckCircleOutlined style={{ color: "orange" }} />
            } else {
                return <CheckCircleOutlined style={{ color: "green" }} />
            }
        }
    }

    return (
        <>
            {/* <div style={{textAlign: "right"}}> */}
            {/* <Title level={4}>{object.tour.levelEvent.event.name + " ("+object.tour.levelEvent.level.name+"), Тур " + object.tour.number + " (" + object.tour.tourType.name + ")"}</Title> */}
            {/* <Typography>Приложение № 1 к Положению</Typography> */}
            {/* </div> */}
            <Row gutter={[5, 5]}>
                <Col span={8}>

                    <Typography>
                        <span style={{ fontSize: "12px", marginRight: "10px" }}>
                            {badge(statistic)}
                        </span>
                        Судья: {object.judge.dancer.lastname + " " + object.judge.dancer.firstname}</Typography>
                </Col>
                <Col span={16}>
                    <Typography>Тур {object.tour.number + ", " + object.tour.levelEvent.event.name + " (" + object.tour.levelEvent.level.name + ")"}</Typography>
                </Col>
            </Row>
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <table border="1" style={{ width: "100%", textAlign: "center" }}>
                        <thead>
                            <tr>
                                <td rowSpan={2} style={{ width: "50px", backgroundColor: "aqua" }}>№ участника</td>
                                <td rowSpan={2} style={{ width: "50px" }}>Сумма</td>

                                {headerLine1()}
                            </tr>
                            <tr>
                                {headerLine2()}
                            </tr>
                        </thead>
                        <tbody>
                            {bodyOuter()}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
}
