import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss'
import { Transfer, message } from 'antd';
import { POST } from '../../components/Tool'

import Filtered from '../../components/Filtered'

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
})

export function Transfering(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const {
        auth,
        REDUX_Source, //REDUX_Dancer
        REDUX_Target, //REDUX_DancerEvent
        CRUDE_Source, //CRUDE_Dancer
        CRUDE_Target, //CRUDE_DancerEvent
        FilterSource, // 's-lastname=ASC&s-firstname=ASC&w-level.id=' + object.levelID
        FilterTarget, // 's-dancer.lastname=ASC&s-dancer.firstname=ASC&w-levelEvent.id=' + object.ID
        dropUrl,      // "/api/dropdancerlistevent"
        addUrl,       // "/api/adddancerlistevent/level-event/" + object.ID
        getSourceIDInTarget, //(item) => item.dancer.ID
        getSourceID, //(item) => item.ID
        Key, //object.ID
        titlesTransfer, //["Все танцоры (" + object.level.name + ")", 'Танцоры группы']
        localeTransfer, //{ itemUnit: "танцор", itemsUnit: "танцор(ов)", notFoundContent: "Нет танцоров", searchPlaceholder: "Поиск" }
        renderTransfer, //item => item.lastname + " " + item.firstname
        items,
        showSearch
    } = props;

    const [selectedKeys, setSelectedKeys] = useState([]);
    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        var all = getSourceOrItems().map(i=>getSourceID(i).toString());
        var selection = [...sourceSelectedKeys, ...targetSelectedKeys].filter(i=>all.includes(i));
        setSelectedKeys(selection);
    };

    const target = useSelector(REDUX_Target.selector.arrayByKey(Key));
    const [targetKeys, setTargetKeys] = useState(target.map(i => getSourceIDInTarget(i).toString()));
    const setTarget = (dr, data) => {
        var ido = dr.filter(item => data.find(i => i === getSourceID(item).toString()) !== undefined);
        setTargetKeys(ido.map(i => getSourceID(i).toString()));
    };

    useEffect(() => {
        setTarget(getSourceOrItems(), target.map(i => getSourceIDInTarget(i).toString()));
        var all = getSourceOrItems().map(i=>getSourceID(i).toString());
        var selection = selectedKeys.filter(i=>all.includes(i));
        setSelectedKeys(selection);
    }, [items]);

    const source = useSelector(REDUX_Source.selector.arrayByKey(Key));
    useEffect(() => {
        if (!items && CRUDE_Source) {
            CRUDE_Source.read(auth, (d) => {
                if (d.length > 0) {
                    dispatch(REDUX_Source.actions.setArrayByKey(Key, d));
                    CRUDE_Target.read(auth, (data) => {
                        if (data.length > 0) {
                            dispatch(REDUX_Target.actions.setArrayByKey(Key, data));
                            setTarget(d, data.map(i => getSourceIDInTarget(i).toString()));
                        }
                    }, 'model', FilterTarget);
                }
            }, 'model', FilterSource);
        } else {
            CRUDE_Target.read(auth, (data) => {
                if (data.length > 0) {
                    dispatch(REDUX_Target.actions.setArrayByKey(Key, data));
                    setTarget(items, data.map(i => getSourceIDInTarget(i).toString()));
                }
            }, 'model', FilterTarget);
        }
    }, [Key, auth, dispatch]);

    const getSourceOrItems = () => {
        if (!items){
            return source;
        } else {
            return items;
        }
    };
    const mockData = getSourceOrItems().map(item => {
        return {
            key: getSourceID(item).toString(),
            ...item
        }
    });

    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        // console.log("nextTargetKeys", nextTargetKeys);
        var nextSelected = [];
        var nextTargets = [];
        if (direction === "left") {
            // Удаляем из target
            // var data = target.filter(i => !nextTargetKeys.includes(getSourceIDInTarget(i).toString()));

            var presents = target.filter(i => !moveKeys.includes(getSourceIDInTarget(i).toString()));
            var deleted = target.filter(i => moveKeys.includes(getSourceIDInTarget(i).toString()));
            nextTargets.push(...presents.map(i=>getSourceIDInTarget(i).toString()));
            POST(auth, dropUrl, deleted,
                () => {
                    // dispatch(REDUX_Target.actions.setArrayByKey(Key, target.filter(i => nextTargetKeys.includes(getSourceIDInTarget(i).toString()))));
                    dispatch(REDUX_Target.actions.setArrayByKey(Key, presents));
                    setTarget(getSourceOrItems(), nextTargets);
                },
                (err, type) => {
                    if (err) {
                        if (type === "fail") {
                            message.error(err.message);
                            if (err.exception) {
                                console.error(err.exception)
                            }
                        }
                        if (type === "error") {
                            message.error(err);
                        }
                        nextTargets.push(...moveKeys);
                        nextSelected.push(...moveKeys);
                        setTarget(getSourceOrItems(), nextTargets);
                        setSelectedKeys(nextSelected);
                    }
                });
        } else {
            // Добавляем новых из source
            var p = target.map(i=>getSourceIDInTarget(i).toString())
            var adata = getSourceOrItems().filter(item => moveKeys.includes(getSourceID(item).toString()));
            var oldtargets = [...targetKeys];
            nextTargets.push(...p, ...moveKeys);
            POST(auth, addUrl, adata,
                (xdata) => {
                    dispatch(REDUX_Target.actions.updateArrayByKey(Key, xdata));
                    setTarget(getSourceOrItems(), nextTargets);
                },
                (err, type) => {
                    if (err) {
                        if (type === "fail") {
                            message.error(err.message);
                            if (err.exception) {
                                console.error(err.exception)
                            }
                        }
                        if (type === "error") {
                            message.error(err);
                        }
                        nextTargets.push(...oldtargets);
                        nextSelected.push(...moveKeys);
                        setTarget(getSourceOrItems(), nextTargets);
                        setSelectedKeys(nextSelected);
                    }
                });
        }
    };
    const filterOption = (inputValue, option) => renderTransfer(option).indexOf(inputValue) > -1;

    return (
        <Transfer
            showSearch={showSearch}
            filterOption={filterOption}
            className={classes.transfer}
            dataSource={mockData}
            titles={titlesTransfer}
            locale={localeTransfer}
            render={renderTransfer}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={handleChange}
            onSelectChange={handleSelectChange}
            // oneWay={true}
            style={{ marginBottom: 16 }}
        />
    );
}

export default function FilteredTransfer(props) {
    const dispatch = useDispatch();
    const {
        auth,
        filters,
        REDUX_Source, //REDUX_Dancer
        REDUX_Target, //REDUX_DancerEvent
        CRUDE_Source, //CRUDE_Dancer
        CRUDE_Target, //CRUDE_DancerEvent
        FilterSource, // 's-lastname=ASC&s-firstname=ASC&w-level.id=' + object.levelID
        FilterTarget, // 's-dancer.lastname=ASC&s-dancer.firstname=ASC&w-levelEvent.id=' + object.ID
        dropUrl,      // "/api/dropdancerlistevent"
        addUrl,       // "/api/adddancerlistevent/level-event/" + object.ID
        getSourceIDInTarget, //(item) => item.dancer.ID
        getSourceID, //(item) => item.ID
        Key, //object.ID
        titlesTransfer, //["Все танцоры (" + object.level.name + ")", 'Танцоры группы']
        localeTransfer, //{ itemUnit: "танцор", itemsUnit: "танцор(ов)", notFoundContent: "Нет танцоров", searchPlaceholder: "Поиск" }
        renderTransfer, //item => item.lastname + " " + item.firstname
        showSearch
    } = props;

    const source = useSelector(REDUX_Source.selector.arrayByKey(Key));
    useEffect(() => {
        if (CRUDE_Source) {
            CRUDE_Source.read(auth, (d) => {
                if (d.length > 0) {
                    dispatch(REDUX_Source.actions.setArrayByKey(Key, d));
                }
            }, 'model', FilterSource);
        } 
    }, [Key, auth, dispatch]);

    return (
        <>
            {filters && <Filtered noPrint={true} noPagination={true} quickFilter={false} title={""} filters={filters} data={source} content={(items, filtered) => (
                <Transfering
                    auth={auth}
                    filters={filters}
                    REDUX_Source={REDUX_Source}
                    REDUX_Target={REDUX_Target}
                    CRUDE_Source={CRUDE_Source}
                    CRUDE_Target={CRUDE_Target}
                    FilterSource={FilterSource}
                    FilterTarget={FilterTarget}
                    dropUrl={dropUrl}
                    addUrl={addUrl}
                    getSourceIDInTarget={getSourceIDInTarget}
                    getSourceID={getSourceID}
                    Key={Key}
                    titlesTransfer={titlesTransfer}
                    localeTransfer={localeTransfer}
                    renderTransfer={renderTransfer}
                    items={items}
                    showSearch={true}//{filtered}
                />
            )} />}
            {!filters && <Transfering
                auth={auth}
                filters={filters}
                REDUX_Source={REDUX_Source}
                REDUX_Target={REDUX_Target}
                CRUDE_Source={CRUDE_Source}
                CRUDE_Target={CRUDE_Target}
                FilterSource={FilterSource}
                FilterTarget={FilterTarget}
                dropUrl={dropUrl}
                addUrl={addUrl}
                getSourceIDInTarget={getSourceIDInTarget}
                getSourceID={getSourceID}
                Key={Key}
                titlesTransfer={titlesTransfer}
                localeTransfer={localeTransfer}
                renderTransfer={renderTransfer}
                showSearch={showSearch}
            />
            }
        </>
    );
}
