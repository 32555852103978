import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';

import 'moment/locale/ru';

import REDUX_City from '../../objects/City/reducer'
import CRUDE_City from '../../objects/City/crude'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Option } = Select;

export default function UpdateDancer(props) {
    const { auth, object } = props;
    const dispatch = useDispatch();

    // useEffect(() => {
    //     CRUDE_Gender.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_Gender.actions.setArray(data));
    //         }
    //     }, 'model');
    // }, []);
    // const optionsGender = gender.map(i => (
    //     <Option key={i.ID} value={i.ID}>{i.name}</Option>
    // ));

    // const level = useSelector(REDUX_LevelDancer.selector.array);
    // useEffect(() => {
    //     CRUDE_LevelDancer.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_LevelDancer.actions.setArray(data));
    //         }
    //     }, 'model', 's-name=DESC');
    // }, []);
    // const optionsLevel = level.map(i => (
    //     <Option key={i.ID} value={i.ID}>{i.name}</Option>
    // ));

    const city = useSelector(REDUX_City.selector.array);
    useEffect(() => {
        CRUDE_City.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_City.actions.setArray(data));
            }
        }, 'model');
    }, [auth, dispatch]);
    const optionsCity = city.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{ ...object,
                // name: object.name || "",
                ...object
            }}
        >
            <Form.Item
                name={"lastname"}
                label={"Фамилия"}
                rules={[{ required: true, message: 'Укажите фамилию!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"firstname"}
                label={"Имя"}
                rules={[{ required: true, message: 'Укажите имя!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"patronymic"}
                label={"Отчество"}
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
                name={"genderID"}
                label={"Пол"}
                rules={[{ required: true, message: 'Укажите пол!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsGender}
                </Select>
            </Form.Item> */}
            {/* <Form.Item
                name={"number"}
                label={"Номер"}
                rules={[{ required: true, message: 'Укажите Номер!' }]}
            >
                <InputNumber min={1}/>
            </Form.Item> */}
            {/* <Form.Item
                name={"levelID"}
                label={"Уровень"}
                rules={[{ required: true, message: 'Укажите Уровень!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsLevel}
                </Select>
            </Form.Item> */}
            <Form.Item
                name={"cityID"}
                label={"Город"}
            // rules={[{ required: true, message: 'Укажите Город!' }]}
            >
                <Select showSearch
                allowClear
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsCity}
                </Select>
            </Form.Item>
            <Form.Item
                name={"phone"}
                label={"Телефон"}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"username"}
                label={"Логин в системе"}
                rules={[{ required: true, message: 'Укажите логин!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"password"}
                label={"Пароль в системе"}
                rules={[{ required: true, message: 'Укажите пароль!' }]}
            >
                <Input.Password
                    placeholder="Введите пароль"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
        </Form>
    );
}
