import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select, InputNumber } from 'antd';

import 'moment/locale/ru';

import REDUX_LevelDancer from '../../objects/LevelDancer/reducer'
import CRUDE_LevelDancer from '../../objects/LevelDancer/crude'

import REDUX_Template from '../../objects/Template/reducer'
import CRUDE_Template from '../../objects/Template/crude'

const { Option } = Select;


export default function UpdateLevelEvent(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;

    const level = useSelector(REDUX_LevelDancer.selector.array);
    useEffect(() => {
        CRUDE_LevelDancer.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_LevelDancer.actions.setArray(data));
            }
        }, 'model', 's-id=ASC');
    }, [auth, dispatch]);
    const optionsLevel = level.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    const template = useSelector(REDUX_Template.selector.array);
    useEffect(() => {
        CRUDE_Template.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_Template.actions.setArray(data));
            }
        }, 'model', 's-id=ASC');
    }, [auth, dispatch]);
    const optionsTemplate = template.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
                ...object
            }}
        >
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"eventID"}
                label={"Мероприятие"}
                rules={[{ required: true, message: 'Укажите мероприятие!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"levelID"}
                label={"Уровень"}
                rules={[{ required: true, message: 'Укажите Уровень!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsLevel}
                </Select>
            </Form.Item>
            <Form.Item
                name={"number"}
                label={"Начало нумерации танцоров в группе"}
                rules={[{ required: true, message: 'Укажите номер!' }]}
            >
                <InputNumber min={1} style={{width: "100%"}}/>
            </Form.Item>
            <Form.Item
            disabled={!!object.templateCloneID}
                name={"templateID"}
                label={"Шаблон критериев"}
                rules={[{ required: true, message: 'Укажите шаблон критериев!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsTemplate}
                </Select>
            </Form.Item>
            <Form.Item
                name={"maxCountEqualScores"}
                label={"Максимально количество одинаковых оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"topScoreIgnore"}
                label={"Количество игнорируемых максимальных оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"bottomScoreIgnore"}
                label={"Количество игнорируемых минимальных оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
        </Form>
    );
}
