import React, { useState } from 'react';
import { Button, Menu, Dropdown } from 'antd';

import {
    MenuOutlined,
} from '@ant-design/icons';


export default function DropdownAction(props){
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (flag) => {
        setVisible(flag);
    }
    const hide = () => {
        setVisible(false);
    }
    const MapChildren = (children) => {
        return React.Children.map(children, child => {
            var o = (child.props && child.props.form)?{ 
                hideMenu: hide 
            }:{};
            return React.cloneElement(child, o)
        }
        );
    };
    const childrenWithProps = MapChildren(props.children);
    return (
        <>
            <Dropdown onVisibleChange={handleVisibleChange} visible={visible} overlay={
            <Menu selectable={false}>
					{childrenWithProps.map((item) =>item)}
            </Menu>}>
                <Button size={"small"} style={{ padding: "0 6px" }} type="default">
                    <MenuOutlined />
                </Button>
            </Dropdown>
        </>
    );
};
